const childrenRoutes = [
  {
    path: "sign-in",
    name: "auth.sign-in",
    props: true,
    meta: {
      title: "Sign In",
      guestOnly: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "auth-sign-in" */ "@/views/auth/sign-in/SignIn"
      ),
  },
  {
    path: "sign-out",
    name: "auth.sign-out",
    meta: {
      title: "Sign Out",
      guestOnly: false,
    },
    component: () => import("@/views/auth/Signout"),
  },
  {
    path: "auth-with-token",
    name: "auth.with-token",
    props: true,
    meta: {
      title: "Auth With Token",
      guestOnly: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "auth-with-token" */ "@/views/auth/sso-sign-in/Index"
      ),
  },
  {
    path: "sign-up",
    meta: {
      title: "Sign Up",
      guestOnly: true,
    },
    name: "auth.sign-up",
    component: () =>
      import(
        /* webpackChunkName: "auth-sign-up" */ "@/views/auth/sign-up/SignUp"
      ),
  },
  {
    path: "forgot-password",
    name: "auth.forgot-password",
    meta: {
      title: "Forgot Password",
      guestOnly: true,
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "auth-forgot-password" */ "@/views/auth/forgot-password/ForgotPassword"
      ),
  },
  {
    path: "reset-password",
    name: "app.reset-password",
    meta: {
      title: "Reset Password",
      guestOnly: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "app.reset-password" */ "@/views/auth/reset-password/ResetPassword"
      ),
  },
  {
    path: "1auq1",
    name: "app.reset-success",
    meta: {
      title: "Reset Password",
      guestOnly: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "app.reset-success" */ "@/views/auth/forgot-password/ForgotPassReqSuccess"
      ),
  },
  {
    path: "12oks0",
    name: "app.signup-success",
    meta: {
      title: "Thank you for signing up",
      guestOnly: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "app.signup-success" */ "@/views/auth/sign-up/SignUpSuccess"
      ),
  },
];

export { childrenRoutes as AuthRoutes };
