<template>
  <div id="app">
    <div>
      <loader v-if="false" />
      <router-view />
    </div>
  </div>
</template>

<script>
import Payments from '@/helpers/payments';
import Loader from "@/components/Shared/Loader.vue";

export default {
  components: {Loader},
  created(){
    this.$store.commit('setPaymentGateway', Payments.gateWays.FLUTTERWAVE);
  },
  computed: {
    isLoading() {
      return this.$store.state.isLoading;
    },
    partner(){
      return this.$store.getters.partner
    }
  },
  watch: {
    partner: {
      handler(newVal){
        if (newVal.pp_key) {
          this.$store.commit('setPaymentGateway', Payments.gateWays.CURACEL_PAY);
        }
      },
      deep: true
    }
  }
}
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.loading-container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.video-loading {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
