/**
 * Use this to indicate a wallet top up
 */
const WALLET_TOP_UP = 'wallet_top_up';

/**
 * Use this to indicate that the payment is for travel insurance
 * from the travel portal
 */
const TRAVEL_PURCHASE = 'buy_travel_insurance';

/**
 * Use this to indicate that the payment is to settle
 * insurance credits
 */
const INSURANCE_CREDIT_REQUEST = 'insurance_credit_request_payment';

/**
 * Payments for card tokenization
 * @var string
 */
const CARD_TOKENIZATION = 'payment_option_card_tokenization';

/**
 * Supported payment gateways
 */
const gateWays = {
  PAYSTACK: 'PS',
  FLUTTERWAVE: 'FW',
  CURACEL_PAY: 'CP',
}

const methods = {
  CARD: 'card',
  WEB_LINK: 'web_link',
  WALLET: 'wallet'
}

const channels = {
  API: 'API',
  DASHBOARD: 'Dashboard',
  WIDGET: 'Widget',
  POCKET: 'Pocket',
  BULK_UPLOAD: 'Bulk_Upload',
  TRAVEL_PORTAL: 'Travel_Portal',
}
const generateRef = function(userId, gateWay, transactionType) {

  const transactionKey = {}

  // this is simply to reduce the chances of duplicate refs
  transactionKey[INSURANCE_CREDIT_REQUEST] = 'ICR';
  transactionKey[WALLET_TOP_UP] = 'WTU';
  transactionKey[TRAVEL_PURCHASE] = 'TRV'

  let text = "";

  const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  for (let i = 0; i < 20; i++){
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }

  return `${gateWay}-${userId}-${text}-${transactionKey[transactionType] || transactionKey[WALLET_TOP_UP]}`;
}

export default {
  WALLET_TOP_UP,
  TRAVEL_PURCHASE,
  INSURANCE_CREDIT_REQUEST,
  CARD_TOKENIZATION,
  gateWays,
  generateRef,
  methods,
  channels
}
